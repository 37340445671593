.login-container {
  height: 100vh;
  background-image: url('../../images/img-17.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  .app-brand {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 100px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .login-form-button {
    width: 100%;
  }
  .copy-link {
    display: block;
    width: 100%;
  }
}
