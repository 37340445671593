.gallery-container {
  user-select: none;
  .ant-row {
    width: 100%;
    .ant-col {
      max-width: 200px;
      width: 100%;
      .folder {
        cursor: pointer;
        &:hover {
          background-color: var(--grey);
        }
      }
      .ant-card {
        background: transparent;
        border-radius: 7px;
        padding: 5px;
        height: 180px;
        text-align: center;
        transition: box-shadow 0.5s ease-out;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        &:hover {
          transition: box-shadow 0.5s ease-out;
        }
        .icon-folder {
          width: 140px;
          height: 150px;
          background: none;
        }
        .ant-card-head {
          min-height: 0;
          .ant-card-head-wrapper {
            .ant-card-head-title {
              padding: 0;
            }
          }
        }
      }
    }
  }
}
.icon-center-delete {
  position: absolute !important;
  top: 5px;
  right: 5px;
  padding: 0;
}
.card-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px !important;
}

[data-theme='dark'] .gallery-container {
  .ant-row {
    .ant-col {
      .folder:hover {
        background-color: var(--dark-blue);
      }
    }
  }
}
