@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  position: relative;
}

body {
  height: 100vh;
  font-family: 'Inter', sans-serif !important;
  user-select: none;

  a {
    text-decoration: none;
  }
}

.card-view {
  &.ant-card-hoverable:hover {
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);
  }

  .ant-card-body {
    padding: 0;

    a {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 24px;
      color: var(--dark);

      .text {
        margin: 0 10px;
        font-weight: 500;
      }

      svg {
        font-size: 22px;
      }
    }
  }
}

img,
svg {
  vertical-align: baseline !important;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #fff, $alpha: 0.5);
  z-index: 2;
  user-select: none;
  pointer-events: none;
}

.ck-editor__editable {
  min-height: 400px;
}

.ant-modal.large-modal {
  width: 800px !important;
}

.label-hidden label {
  visibility: hidden;
}

.ant-descriptions:not(.ant-descriptions-bordered) {
  .ant-descriptions-item-label {
    font-weight: bold;
  }
}

.ant-card.h-100 {
  .ant-card-body {
    height: 100%;
  }
}

.global-settings {
  .ant-card.h-100 {
    .ant-card-body {
      height: auto;
    }
  }
}

.extra-color-wrapper-contain {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.extra-img-wrapper-contain {
  width: auto;
  max-height: 25px;
  margin-top: 7px;
  border-radius: 8px;
}

.page-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #fafafb;
}

[data-theme='dark'] {
  .loader {
    background-color: rgba($color: #1b2531, $alpha: 0.5);
  }

  .page-loading {
    background-color: var(--dark-blue);
  }

  .ant-menu-inline {
    border-right: none;
  }

  .card-view {
    .ant-card-body {
      a {
        color: var(--light-text);
      }
    }
  }

  .antd-toast {
    color: var(--light-text);

    .Toastify__toast-theme--light {
      color: var(--light-text);
      background: var(--dark-blue);
    }

    .Toastify__close-button--light {
      color: var(--light-text);
    }
  }

  .ck.ck-editor__main > .ck-editor__editable {
    background: var(--dark-background);
  }

  .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
    border-color: var(--dark-grey);
  }

  .ck.ck-toolbar {
    background: var(--dark-background);
    border-color: var(--dark-grey);

    * {
      color: var(--light-text) !important;
    }
  }

  .ck.ck-button:not(.ck-disabled):hover,
  a.ck.ck-button:not(.ck-disabled):hover {
    background: var(--dark-grey);
  }

  .ck.ck-toolbar .ck.ck-toolbar__separator {
    background: var(--dark-grey);
  }
}


.map-button {
  cursor: pointer !important;
  position: absolute;
  z-index: 99;
  right: 10px;
  bottom: 115px;
  border: none;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;

  img {
    width: 20px;
    height: 20px;
  }
}

.map-container {
  position: relative;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin: 10px 0 !important;
}

.productDescription .ck .ck-content {
  min-height: 100px !important;
  max-height: 100px !important;
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.productDescription .ck .ck-toolbar {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}